import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, forwardRef, useMemo, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';

interface Option {
    label: string;
    value: any;
}

interface FilterSelectProps {
    options: Option[];
    selected?: Option;
    enableSorting?: boolean;
    onChange?: (e: Option) => void;
}

const FilterSelect = forwardRef((props: FilterSelectProps, ref) => {
    const { options, selected, enableSorting = false, onChange } = props;
    const [query, setQuery] = useState<any>('');

    const sortedOptions = useMemo(() => {
        return enableSorting ? options.sort((a, b) => a.label?.localeCompare(b.label)) : options;
    }, [options, enableSorting]);

    const filteredOption = useMemo(() => {
        return query === ''
            ? sortedOptions
            : sortedOptions.filter((option) =>
                  option.label
                      .toLowerCase()
                      .replace(/\s+|\//g, '')
                      .includes(query.toLowerCase().replace(/\s+|\//g, ''))
              );
    }, [sortedOptions, query]);

    return (
        <Combobox value={selected} onChange={onChange}>
            <div className="relative">
                <div className="relative w-full cursor-default overflow-hidden rounded-md bg-brand-background-dark border border-neutral-700 text-left shadow-md sm:text-sm">
                    <Combobox.Button className="w-full">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-neutral-200 bg-brand-background-dark outline-none"
                            displayValue={(option: any) => option?.label}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <MdExpandMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute z-[60] mt-2 max-h-60 w-full overflow-auto rounded-md bg-brand-background-dark border border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900">
                        {filteredOption.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-neutral-200">
                                Nothing found.
                            </div>
                        ) : (
                            filteredOption.map((option, optionIdx) => (
                                <Combobox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                        cn(`relative cursor-pointer select-none py-2 pl-3 pr-4`, {
                                            'bg-brand-red': active,
                                            'text-brand-red-light': selected?.value === option.value,
                                            'text-neutral-200': active && selected?.value === option.value
                                        })
                                    }
                                    value={option}>
                                    <span className={cn(`block truncate`)}>{option?.label}</span>
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
});

FilterSelect.displayName = 'FilterSelect';

export default FilterSelect;
